<template>
  <div>
    <div class="container-fluid">
      <div class="row text-center">
        <div class="w-100 d-flex justify-content-center" style="margin-top: 5%">
          <b-alert :show="!errorMessageShow" variant="warning">
            {{ $t('loginRegister.activationProcMsg') }}
          </b-alert>
          <b-alert
            :show="dismissCountDown"
            dismissible
            variant="danger"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            <p>
              {{ this.errorMessage }}
              {{ $t('loginRegister.redirectMsg') }}
              {{ dismissCountDown }}
              {{ $t('loginRegister.second') }}
            </p>
            <b-progress variant="warning" :max="dismissSecs" :value="dismissCountDown" height="4px">
            </b-progress>
          </b-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  name: 'token',
  mixins: [DefaultHeadMixin],
  layout: 'default',
  data: () => ({
    errorMessage: '',
    errorMessageShow: false,
    dismissSecs: 10,
    dismissCountDown: 0,
  }),
  mounted() {
    this.accountActivation();
  },
  methods: {
    async accountActivation() {
      let response = await this.$store.dispatch(
        'global/accountActivation',
        this.$route.params.token,
      );
      if (response && response.type === 'success') {
        await this.$store.dispatch('global/updateToken', response.data.token);
        await this.$store.dispatch('global/fetch');
        this.$router.replace('/profile');
      } else {
        this.errorMessageShow = true;
        this.dismissCountDown = 10;
        this.errorMessage = response.error;

        setTimeout(() => {
          this.$router.replace('/login');
        }, 10000);
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>

<style scoped></style>
